import { List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, IconButton } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import IconDownload from '@mui/icons-material/Publish';
import { formatBytes } from 'services/size-format-service';
import { formatDateTimeFromMillis } from 'services/date-service';
import Loader from 'components/loader/loader';
import { FileDTO } from 'types';
import fileService from 'services/file-service';
import 'components/download/project-download.scss';
import useFileDownloader from 'components/download/useFileDownloader';
import FileShare from 'components/download/file-share';

type Props = {
    projectId?: number,
    siteId?: number,
    projectFiles?: FileDTO[],
};
  
const BrowserDownloadTab = ({ projectId, siteId = 0, projectFiles }: Props) => {

    const [downloadFile, downloaderComponentUI] = useFileDownloader();

    const download = (f: FileDTO) => {
        const filename = f.shortName.includes('/') ? getFileName(f.shortName) : f.shortName;
    
        if (f.size / Math.pow(1024, 3) >= 1) // If file size is over 1GB
          fileService.downloadFileFromBlob(siteId ? siteId : -1, projectId ? projectId : -1, f.shortName);
        else {
          let url;
          if (siteId) {
            url = `/api/cloud-storage/site/${siteId}/files/${f.shortName}`;
          } else {
            url = `/api/cloud-storage/project/${projectId}/files/${f.shortName}`;
          }
    
          const file = {
            fileUrl: url,
            filename: filename,
          };
          downloadWithProgressBar(file);
        }
    };

    const getFileName = (filePath) => {
        return filePath.substring(filePath.lastIndexOf('/') + 1);
    };

    const downloadWithProgressBar = (file) => downloadFile(file);

    return (
        <div className='files-container'>
            <div className='files-title'>Files</div>
            {!projectFiles ? (
            <div className='waiting-for-files-section'>
                <Loader className='loader' />
                <div>Waiting for files</div>
            </div>
            ) : (projectFiles.length === 0 ? (
            <div>
                <div>No file available for download</div>
            </div>
            ) : (
            <div className='file-list'>
                <List dense>
                {projectFiles
                    .sort((f1, f2) => f1.name.localeCompare(f2.name))
                    .map((file) => (
                    <ListItem key={file.shortName} className={'list-item'} button onClick={() => download(file)}>
                        <ListItemAvatar>
                        <InsertDriveFileIcon />
                        </ListItemAvatar>
                        <ListItemText
                        primary={`${file.shortName} - (size : ${formatBytes(file.size)})`}
                        secondary={`Created : ${formatDateTimeFromMillis(file.creationDatetime)}`} />

                        <ListItemSecondaryAction>
                        <div className='action-buttons'>
                            <IconButton color='primary' className='download-button' edge='end' aria-label='comments' onClick={() => download(file)}>
                            <IconDownload className='download-icon-reversed' />
                            </IconButton>
                            <FileShare fileToShare={file} siteId={siteId} projectId={projectId || 0} />
                        </div>
                        </ListItemSecondaryAction>
                    </ListItem>
                    ))}
                </List>
            </div>
            ))}
            {downloaderComponentUI}
        </div>
    );
};

export default BrowserDownloadTab;