import { Slider, Button } from '@mui/material';
import OsSelection, { selectedOsValue, selectedProxyValue } from 'components/shared/os-selection/os-selection';
import { useState } from 'react';
import fileService from 'services/file-service';
import 'components/download/project-download.scss';

type Props = {
    projectId?: number,
    siteId?: number,
};
  
const CommandLineDownloadTab = ({ projectId, siteId = 0 }: Props) => {

    const minSasTokenLifetimeInHours = 1;
    const maxSasTokenLifetimeInHours = 168;
    const sliderStepSasToken = 1;
    const minBandwidthInMB = 0; //0 is for unlimited bandwith
    const maxBandwidthInMB = 100;
    const sliderStepBandwith = 10;
    const [sasTokenLifetimeInHours, setSasTokenLifetimeInHours] = useState(12);
    const [bandwidthInMB, setBandwidthInMB] = useState(0);

    const handleSasTokenLifetimeChange = (event, newValue) => {
        setSasTokenLifetimeInHours(newValue);
    };

    const handleBandwithChange = (event, newValue) => {
        setBandwidthInMB(newValue);
    };

    const commandLineDownload = () => {
        fileService.downloadFilesFromComamndLine(siteId || -1, projectId || -1, selectedOsValue, selectedProxyValue, sasTokenLifetimeInHours, bandwidthInMB);
    };

    return (
        <div>
            <div className='description'>
                <div>To download all the files, please follow these steps :</div>
                <br/>
                <div>1. Select your OS and a proxy if necessary</div>
                <div>2. A "download-script" will be downloaded to your computer</div>
                <div>3. <strong>Execute</strong> that script and <strong>wait</strong> for completion of the download</div>
                <div>4. The downloaded files are now accessible in the directory where the script was executed</div>
            </div>
            <br/>
            <OsSelection/>
            <p>Max download time: {sasTokenLifetimeInHours} hours</p>
            <Slider value={sasTokenLifetimeInHours} min={minSasTokenLifetimeInHours} max={maxSasTokenLifetimeInHours}
                        step={sliderStepSasToken} aria-labelledby='label' onChange={handleSasTokenLifetimeChange} />
            <br/>
            <p> Max bandwidth: {bandwidthInMB === 0 ? 'Unlimited' : bandwidthInMB + 'MB'}</p>
            <Slider value={bandwidthInMB} min={minBandwidthInMB} max={maxBandwidthInMB}
                        step={sliderStepBandwith} aria-labelledby='label' onChange={handleBandwithChange} />
            <div className='buttonContainer'>
            <Button onClick={commandLineDownload} variant='contained' color='primary' className='validationButton' disabled={false}>
                DOWNLOAD
            </Button>
            </div>
        </div>        
    );
};

export default CommandLineDownloadTab;