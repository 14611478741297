/* eslint-disable linebreak-style */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { ReactComponent as AddSchedule } from 'assets/images/add-schedule.svg';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { isSiteManager } from 'store/selectors/manager-selector';

import { useAppDispatch } from 'store';
import { getSiteInfo } from 'store/actions/resource-site-actions';
import { RootState } from 'store/reducers';
import { VmDTO } from 'types';
import styles from './vm-edit-lifetime.module.scss';

const mapState = (state: RootState) => ({
  endTime: 0,//FIXME
  isFetching: state.infoSiteReducer.isFetching,
  siteInfo: state.infoSiteReducer.siteInformation,
  isSiteManager: (siteId: number) => isSiteManager(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  children: React.ReactNode,
  vmInstance: VmDTO,
  onUpdate: (date: Date) => Promise<void>
};

type Props = ReduxProps & OwnProps;

const MAX_DAYS_EXTEND_AT_A_TIME = 7;
const MAX_VM_LIFETIME_ALLOWED_IN_SECONDS = 9 * 24 * 3600;
const EditLifeTimeVm = (
  {
    vmInstance,
    children,
    onUpdate,
    siteInfo,
    endTime,
  }: Props
) => {
  let expendLifeTimeMsgErr = 'Up to 7 days from now';
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSiteInfo(vmInstance.siteId));
  }, [getSiteInfo, vmInstance.siteId]);
  const secondToDay = (seconds: number) => {
    return Math.trunc(seconds / (3600 * 24));
  };

  const getMaxTime = () => {
    const currentDate = new Date(); //today's date and time
    const creationDate = new Date(vmInstance.creationDate);
    const maxVmExpirationDateAllowed = new Date(creationDate.getTime());
    const nbSecondInMaxVmExpirationDate = maxVmExpirationDateAllowed.getTime();
    if (siteInfo) {
      maxVmExpirationDateAllowed.setTime(nbSecondInMaxVmExpirationDate + siteInfo.maxExtendVmLifeTimeAllowedInSecond * 1000);
    } else {
      maxVmExpirationDateAllowed.setTime(nbSecondInMaxVmExpirationDate + MAX_VM_LIFETIME_ALLOWED_IN_SECONDS * 1000);
    }
    const maxExtendLifeTime = new Date(currentDate.getTime());
    maxExtendLifeTime.setDate(maxExtendLifeTime.getDate() + MAX_DAYS_EXTEND_AT_A_TIME);
    if (maxVmExpirationDateAllowed.getTime() < maxExtendLifeTime.getTime()) {
      let MaxDayExtendLifetime = secondToDay(MAX_VM_LIFETIME_ALLOWED_IN_SECONDS);
      if (siteInfo) {
        MaxDayExtendLifetime = secondToDay(siteInfo.maxExtendVmLifeTimeAllowedInSecond);
      }
      expendLifeTimeMsgErr = `You can extend up to ${MAX_DAYS_EXTEND_AT_A_TIME} days at a time, but you cannot extend more than ${MaxDayExtendLifetime} days in total`;
      return DateTime.fromJSDate(maxVmExpirationDateAllowed);
    }
    expendLifeTimeMsgErr = 'Up to 7 days from now';
    return DateTime.fromJSDate(maxExtendLifeTime);
  };


  // return the latest date between vmExpirationDate and siteDefaultExpirationDate
  const defaultEndTime = (vmShutdownDateMillis: number) => {
    const expirationDate = DateTime.fromMillis(vmShutdownDateMillis);

    const todayMidnight = new Date();
    todayMidnight.setHours(0);
    todayMidnight.setMinutes(0);
    todayMidnight.setSeconds(0);

    const siteDefaultExpDate = new Date(todayMidnight.getTime() + endTime * 1000);
    const now = new Date();

    if (siteDefaultExpDate < now) {
      // if site default expiration time is passed, set it to tomorrow
      siteDefaultExpDate.setDate(todayMidnight.getDate() + 1);
    }

    const siteDefaultExpirationDateLuxon = DateTime.fromJSDate(siteDefaultExpDate);

    return expirationDate < siteDefaultExpirationDateLuxon ? siteDefaultExpirationDateLuxon : expirationDate;
  };

  const [showModal, setShowModal] = useState(false);
  const [endDate, setEndDate] = useState(defaultEndTime(vmInstance.shutdownDateInMillis)); // Date of end of lifetime vm

  const handleClickOpen = event => {
    setShowModal(true);
    event.stopPropagation();
  };

  const handleClose = event => {
    closeModal(event);
    setEndDate(defaultEndTime(vmInstance.shutdownDateInMillis));
  };

  const closeModal = event => {
    setShowModal(false);
    event.stopPropagation();
  };

  const updateVM = event => {
    if (onUpdate) {
      onUpdate(endDate).catch(() => setEndDate(defaultEndTime(vmInstance.shutdownDateInMillis)));
    }
    closeModal(event);
  };
  // for updating expendLifeTimeMsgErr before rendering KeyboardDateTimePicker component -> updated to DatePicker after migrating to mui v5
  getMaxTime();
  return (
    <div onClick={handleClickOpen}>
      <div>{children}</div>
      <Dialog open={showModal} onClose={handleClose} fullWidth maxWidth='sm' aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle>
          <AddSchedule className={styles.icon} />
          <div className={styles.customRunTitle}>Modify vm lifetime</div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ 'marginBottom': '12px' }}>
            <span className={styles.customRunTitle}> VM :</span> {vmInstance.name}
          </DialogContentText>
          <div>
            <span className={styles.label}> New end time : </span>
            <DialogContentText>
              <DateTimePicker
                onChange={(datetime) => setEndDate(datetime)}
                value={endDate}
                maxDate={getMaxTime()}
                format='yyyy/MM/dd HH:mm'
                slotProps={{
                  textField: {
                    helperText: expendLifeTimeMsgErr,
                    sx: {
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0, borderBottom: '1px solid black', borderRadius: 0 },
                      '.MuiOutlinedInput-input': { padding: '4px 0 5px' },
                      '.MuiFormHelperText-root': { marginLeft: 0 },
                    },
                  },
                }}
                views={['day', 'hours', 'minutes']}
                ampm={false}
                disablePast
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}/>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='stretch' direction='row' justifyContent='center' className={styles.accessAttributionButtonGrid}>
            <Grid item>
              <Button onClick={handleClose} className={styles.cancelButton} sx={{ color: 'black' }}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={updateVM} variant='contained' color='primary' className={styles.validationButton}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connector(EditLifeTimeVm);
