import { useEffect } from 'react';

import styles from './zone-info.module.scss';

import Loader from 'components/loader/loader';
import { connect, ConnectedProps } from 'react-redux';
import { getZoneInformation } from 'store/actions/resource-zone-actions';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PropTypes from 'prop-types';
import { formatDateFromISO } from 'services/date-service';
import { round1Decimal } from 'services/float-service';
import zoneService from 'services/zone-service';
import { useAppDispatch } from 'store';
import { RootState } from 'store/reducers';
import { isZoneManager } from 'store/selectors/manager-selector';
import { StorageBackupType, ZoneInfoDTO } from 'types';
import EditZone from './edit-zone';
import ZoneManagerList from './zone-manager-list';


const mapState = (state: RootState) => ({
  isFetchingZoneInfo: state.infoZoneReducer.isFetchingZoneInformation,
  zoneInfo: state.infoZoneReducer.zoneInformation,
  getZoneInformation: PropTypes.func.isRequired,
  isZoneManager: (zoneId: number) => isZoneManager(state, zoneId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  zoneId: number
};

type Props = ReduxProps & OwnProps;

const ZoneInfo = ({ zoneId, isFetchingZoneInfo, zoneInfo, isZoneManager }: Props) => {
  const dispatch = useAppDispatch();
  const onUpdate = (zone: ZoneInfoDTO, newFileshareSizeInGB: number, snapshotsEnabled: boolean, backupSolution: StorageBackupType) =>
    // eslint-disable-next-line no-param-reassign
    zoneService.patchZone(zone.id, newFileshareSizeInGB, snapshotsEnabled, backupSolution).then((newZone) => (zone.fileshareSize = newZone.fileshareSize));

  useEffect(() => {
    dispatch(getZoneInformation(zoneId));
  }, [zoneId]);

  return (
    <div className={styles.zone_info_container}>
      {isFetchingZoneInfo && (
        <div className={styles.zone_info_container__zone_info_loader_container}>
          <Loader/>
        </div>
      )}

      {zoneInfo && (
        <>
          <div className={styles.zone_info_container__title}>STORAGE INFORMATION</div>
          <div className={styles.zone_info_container__zone_info}>
            <div className={styles.zone_info_container__zone_info__header}>
              <span className={styles.zone_info_container__zone_info__header__header_text}>{zoneInfo.name}</span>
              {isZoneManager(zoneInfo?.id)
              && <EditZone zone={zoneInfo} key={zoneInfo.id} 
                    onUpdate={(newFileshareSizeInGB, snapshotsEnabled, backupSolution) => onUpdate(zoneInfo, newFileshareSizeInGB, snapshotsEnabled, backupSolution)}/>}
            </div>
            <div className={styles.zone_info_container__zone_info__content}>
              <div className={styles.zone_info_container__zone_info__content__section_items}>
                <div className={styles.zone_info_container__zone_info__content__section_items__item}>
                  <div className={styles.zone_info_container__zone_info__section_label}>Fileshare Capacity</div>
                  <div className={styles.zone_info_container__zone_info__value}>{round1Decimal(zoneInfo.fileshareSize / 1024)} TB</div>
                </div>
                <div className={styles.zone_info_container__zone_info__content__section_items__item}>
                  <div className={styles.zone_info_container__zone_info__section_label}>Fileshare Usage</div>
                  <div className={styles.zone_info_container__zone_info__value}>{round1Decimal(zoneInfo.fileshareDiskUsage ? zoneInfo.fileshareDiskUsage * 100 : 0.0)}%
                    {zoneInfo.fileshareDiskUsage >= zoneInfo.diskUsageThreshold && <ReportProblemIcon style={{ color: 'orange', marginLeft: '10px' }} />}</div>
                </div>

                <div className={styles.zone_info_container__zone_info__content__section_items__item}>
                  <div className={styles.zone_info_container__zone_info__section_label}>Storage Creation Date</div>
                  <div className={styles.zone_info_container__zone_info__value}>{formatDateFromISO(zoneInfo.storageCreationDate)}</div>
                </div>

                <div className={styles.zone_info_container__zone_info__content__section_items__item}>
                  <div className={styles.zone_info_container__zone_info__section_label}>Storage Type</div>
                  <div className={styles.zone_info_container__zone_info__value}>{zoneInfo.storageType}</div>
                </div>
              </div>
              <div className={styles.zone_info_container__zone_info__managers}>
                <div className={styles.zone_info_container__zone_info__informations_label}>Zone managers</div>
              </div>
              {zoneInfo.managers && <ZoneManagerList managerList={zoneInfo.managers} isFetchingManagerList={isFetchingZoneInfo}/>}
            </div>
          </div>
        </>
      )}
    </div>
  );
};


export default connector(ZoneInfo);
