import { useEffect } from 'react';

import Loader from 'components/loader/loader';
import EditSite from 'components/manage-sites/edit-site';
import { countries } from 'country-data';
import ReactMarkdown from 'react-markdown';
import { connect, ConnectedProps } from 'react-redux';
import { formatDateFromISO, formatTimeFromSeconds } from 'services/date-service';
import { useAppDispatch } from 'store';
import { getSiteInfo } from 'store/actions/resource-site-actions';
import { RootState } from 'store/reducers';
import { isSiteManager } from 'store/selectors/manager-selector';
import SiteCapability from './site-capability';
import './site-info.scss';
import SiteManagersInfo from './site-managers-info';

const mapState = (state: RootState) => ({
  isFetching: state.infoSiteReducer.isFetching,
  site: state.infoSiteReducer.siteInformation,
  isSiteManager: (siteId: number) => isSiteManager(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps & {
  siteId: number
};

const SiteInfo = ({ siteId, site, isFetching, isSiteManager }: Props) => {
  useEffect(() => {
    dispatch(getSiteInfo(siteId));
  }, [getSiteInfo, siteId]);

  const dispatch = useAppDispatch();
  const getDescription = htmlDesc => <ReactMarkdown children={htmlDesc}/>;

  const getRunLevelLabel = (runLevel: number) => {
    if (runLevel === 0) {
      return 'Site';
    }
    if (runLevel === 2) {
      return 'Project';
    }
    if (runLevel > 2) {
      return 'Sub-project';
    }
    else {
      return 'Not managed';
    }
  }

  return (
    <div className='site-information-container'>
      {!site && isFetching && (
        <div className='loadingSpinner'>
          <Loader/>
        </div>
      )}
      {site && (
        <>
          <div className='section-title'>SITE INFORMATION</div>
          <div className='site-container'>
            <div className='top-banner-site__panel'>
              <span className='top-banner-site__title'>{site.label}</span>
              {isSiteManager(site.id) && <EditSite siteInfo={site}/>}
            </div>
            <div className='site-detail-block'>
              <div className='section-items'>

                <div className='item'>
                  <div className='label-item'>Site creation date</div>
                  <div className='value-item'>{formatDateFromISO(site.createdDate)}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Datacenter country</div>
                  <div className='value-item'>{countries[site.countryCode].name}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>User home</div>
                  <div className='value-item'>{site.vmPersistentHomeDir ? 'Persistent' : 'Ephemeral'}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Session launch level</div>
                  <div className='value-item'>{getRunLevelLabel(site.runLevel)}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Max sessions per user</div>
                  <div className='value-item'>{site.maxSessionPerUser}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Max session lifetime</div>
                  <div className='value-item'>{site.maxExtendVmLifeTimeAllowedInSecond}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Default vm endtime</div>
                  <div className='value-item'>{formatTimeFromSeconds(site.defaultVmTTLInSeconds)}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Custom install regex</div>
                  <div className='value-item'>{site.sismageAllowedVersionsRegex}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Sudoer status</div>
                  <div className='value-item'>{site.sudoTTLInSeconds === -1 ? 'Disabled' : 'Permitted'}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Storage expiration email</div>
                  <div className='value-item'>{site.expirationNotificationEnabled ? 'Enabled' : 'Disabled'}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Launched Vms</div>
                  <div className='value-item'>{site.launchedVms ? site.launchedVms : 0}</div>
                </div>

                <div className='item'>
                  <div className='label-item'>Selected Sismage version</div>
                  <div className='value-item'>{site.selectedSismageVersion ? site.selectedSismageVersion : 'latest'}</div>
                </div>
              </div>
            </div>
            <div className='site-desciption-block'>
              <div className='site-desciption-block__title'>Description or Comments</div>
              <div className='site-desciption-block__content'>{getDescription(site.description)}</div>
            </div>
            <SiteManagersInfo siteInfo={site}/>
            <SiteCapability siteInfo={site}/>
          </div>
        </>
      )}
    </div>
  );
};


export default connector(SiteInfo);
