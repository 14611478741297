import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store';
import { clearProjectFilesAction, getProjectFilesAction } from 'store/actions/file-download-actions';
import { RootState } from 'store/reducers';
import './project-download.scss';
import BrowserDownloadTab from './browser-download-tab/browser-download-tab';
import CommandLineDownloadTab from './command-line-download-tab/command-line-download-tab';
import TablePagination from '@mui/material/TablePagination';

const mapState = (state: RootState) => ({
  projectFiles: state.fileDownloadReducer.projectFiles,
  currentPage: state.fileDownloadReducer.currentPage,
  pageSize: state.fileDownloadReducer.pageSize,
  totalElements: state.fileDownloadReducer.totalElements,
});

const mapDispatch = (dispatch) => bindActionCreators(
  {
    clearProjectFilesAction,
  },
  dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  projectId?: number,
  siteId?: number,
};

type Props = ReduxProps & OwnProps;

const ProjectDownload = ({
                           projectId,
                           siteId = 0,
                           projectFiles,
                           clearProjectFilesAction,
                           currentPage,
                           pageSize,
                           totalElements,
}: Props) => {

  const [sId, setsId] = useState<number>(siteId || 0);
  const [pId, setpId] = useState<number | undefined>(projectId);
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage =  parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(getProjectFilesAction(sId, pId, 0, newRowsPerPage));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPageNumber: number
  ) => {
    setPage(newPageNumber);
    dispatch(getProjectFilesAction(sId, pId, newPageNumber, rowsPerPage));
  };

  useEffect(() => {
    setpId(projectId);
    setsId(siteId);
    dispatch(getProjectFilesAction(sId, pId, page, rowsPerPage));

    return () => {
      clearProjectFilesAction();
    };
  }, [projectId, siteId, sId, pId, getProjectFilesAction, clearProjectFilesAction]);

  return (
    <>
        <div className='project-download'>
      <div className='title'>DOWNLOAD</div>
      <div className='container'>
        <div className='description'>
          <div>To make files available for download, please move them to the /export using one of your sessions.</div>
          <div>Files in the /export directory will be removed automatically after a few days.</div>
          <div>Once moved/copied to this directory, the file will appear below and will be available for download or be shared through a temporary link to an email of your choosing</div>
          <br/>
          <div><strong>BROWSER</strong> download is the right choice if you want to download a <strong>specific</strong> file</div>
          <div><strong>COMMAND LINE</strong> download is best suited if you want to download <strong>all</strong> of the files or for <strong>large</strong> files that take hours to download.</div>
        </div>

        <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
          <Tab label='BROWSER' />
          <Tab label='COMMAND LINE' />
        </Tabs>
        {tabIndex === 0 && (
          <>
            <BrowserDownloadTab projectId={pId} siteId={sId} projectFiles={projectFiles}/>
          </>
        )}
        {tabIndex === 1 && (
          <>
             <CommandLineDownloadTab projectId={pId} siteId={sId}/>
          </>
        )}
      </div>
    </div>
  <TablePagination
    component='div'
    count={totalElements}
    page={page}
    onPageChange={(event, page) => handleChangePage(event, page)}
    rowsPerPage={rowsPerPage}
    labelRowsPerPage='Files per page'
    onRowsPerPageChange={event => handleChangeRowsPerPage(event)}/>
</>
  );
};

export default connector(ProjectDownload);