import { ANF_NATIVE_BACKUP_POLICY, BACKUP_POLICY_DISABLED, BACKUP_TYPES } from 'constants/backup-policy';
import { DEFAULT_SNAPSHOT_POLICY, SNAPSHOT_POLICY_DISABLED } from 'constants/snapshot-policy';
import { receiveZoneFileshareFailedAction, receiveZoneFileshareReadyAction } from 'store/actions/resource-zone-actions';
import { CreateZoneDTO, PricingInfoDTO, StorageBackupType, UpdateZoneDTO, ZoneDTO, ZoneInfoDTO } from 'types';
import instance from './axios';
import { format1Decimal } from './float-service';
import toastService from './toast-service';

export default {
  addZone(body: CreateZoneDTO) {
    return instance
      .post('/api/resource/zone', body)
      .then((res) => {
        toastService.success('Storage added successfully');
        return res.data;
      })
      .catch(() => {
        toastService.error('Error while adding zone');
        return Promise.reject();
      });
  },
  deleteZone(zoneId: number): Promise<void> {
    return instance
      .delete(`/api/resource/zone/${zoneId}`)
      .then(() => {
        toastService.success('Storage deleted successfully');
      })
      .catch((error) => {
        toastService.error(error?.response?.data?.message);
        return Promise.reject();
      });
  },
  getZoneFileshareStatus(zoneId: number): Promise<ZoneDTO> {
    return instance.get(`/api/resource/zone/${zoneId}`).then((res) => res.data);
  },
  getZoneFileshareStatusWithPolling(siteId: number, zoneId: number, dispatch) {
    this.getZoneFileshareStatus(zoneId).then((zoneInfo) => {
      if (zoneInfo.fileshareStatus === 'READY') {
        dispatch(receiveZoneFileshareReadyAction(siteId, zoneId));
      } else if (zoneInfo.fileshareStatus === 'FAILED') {
        dispatch(receiveZoneFileshareFailedAction(siteId, zoneId));
      } else {
        setTimeout(() => this.getZoneFileshareStatusWithPolling(siteId, zoneId, dispatch), 5000);
      }
    });
  },
  patchZone(zoneId: number, fileshareSizeInGB: number, snapshotsEnabled: boolean, backupSolution: StorageBackupType) {
    let snapshotPolicy = SNAPSHOT_POLICY_DISABLED;
    if (snapshotsEnabled) {
      snapshotPolicy = DEFAULT_SNAPSHOT_POLICY;
    }
    const body: UpdateZoneDTO = {
      fileshareSize: fileshareSizeInGB,
      snapshotPolicy: snapshotPolicy,
      backupPolicy: backupSolution.code === BACKUP_TYPES.ANF_NATIVE_BACKUP ? ANF_NATIVE_BACKUP_POLICY : BACKUP_POLICY_DISABLED,
    };
    return instance
      .patch(`/api/resource/zone/${zoneId}`, body)
      .then((res) => {
        toastService.success('Storage modification in progress. It may take a few minutes to be visible in existing sessions.');
        return res.data;
      })
      .catch((error) => {
        toastService.error(`Error while updating storage ${error?.response?.data?.message}`);
        return Promise.reject();
      });
  },
  getZoneFilesharePricing(siteId: number, category: string): Promise<PricingInfoDTO> {
    // Storage price per GiB per month
    return instance.get(`/api/resource/zone/pricing?siteId=${siteId}&category=${category}`).then((res) => res.data);
  },
  getZoneInfo(zoneId: number): Promise<ZoneInfoDTO> {
    return instance.get(`api/resource/zone-info/${zoneId}`).then((res) => res.data);
  },
  calculateStoragePrice(zoneFileshareSizeInTiB: number, price: number, withBackups: boolean): string {
    const zoneFileshareSizeInGiB = zoneFileshareSizeInTiB * 1024;
    const basePrice = zoneFileshareSizeInGiB * price;
    let storagePrice = basePrice;

    if (withBackups) {
      const dailyBackupsToKeep = ANF_NATIVE_BACKUP_POLICY.dailyBackupsToKeep ? ANF_NATIVE_BACKUP_POLICY.dailyBackupsToKeep : 0;
      const weeklyBackupsToKeep = ANF_NATIVE_BACKUP_POLICY.weeklyBackupsToKeep ? ANF_NATIVE_BACKUP_POLICY.weeklyBackupsToKeep : 0;
      const monthlyBackupsToKeep =  ANF_NATIVE_BACKUP_POLICY.monthlyBackupsToKeep ?  ANF_NATIVE_BACKUP_POLICY.monthlyBackupsToKeep : 0;
      const numberBackups = dailyBackupsToKeep + weeklyBackupsToKeep + monthlyBackupsToKeep;
      const backupPrice = (zoneFileshareSizeInGiB + zoneFileshareSizeInGiB * numberBackups * 0.01) * 0.05;

      storagePrice = basePrice + backupPrice;
    }
    
    return format1Decimal(storagePrice);
  },
};
