import qs from 'qs';
import { AccessMgtDataDTO, CreateAccessDTO, FilterAclObject, ResourceAccessTree, SortObject, UpdateAccessDTO, UserDTO } from 'types';
import instance from './axios';
import toastService from './toast-service';

export default {
  createAccessControl(body: CreateAccessDTO): Promise<void> {
    return instance
      .post('/api/access-control', body)
      .then(() => {
        toastService.success('Users added successfully');
      })
      .catch(() => {
        toastService.error('Error while adding users');
        return Promise.reject();
      });
  },
  getUserListToManage(page: number, rowsPerPage: number, searchKeyword: string, sortCriteria: SortObject, filterList: FilterAclObject): Promise<AccessMgtDataDTO> {
    const search = searchKeyword ? `&search=${searchKeyword}` : '';
    const url = `/api/access-control/management?page=${page}&size=${rowsPerPage}${search}`;
    return instance.get(url, {
      params: {
        name: sortCriteria.name,
        direction: sortCriteria.direction,
        siteLabel: filterList.siteLabel,
        zoneName: filterList.zoneName,
        projectName: filterList.projectName,
        permissionAuthority: filterList.permissionAuthority,
        expired: filterList.expired,
        expireSoon: filterList.expireSoon,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { allowDots: true });
      },
    }).then(res => res.data);
  },
  getUserAcl(): Promise<ResourceAccessTree> {
    return instance.get('/api/resources').then(res => res.data);
  },
  updateUserAccessControl(accessControlId: number, body: UpdateAccessDTO): Promise<void> {
    return instance
      .put(`/api/access-control/${accessControlId}`, body)
      .then(() => {
        toastService.success('Authority updated successfully');
      })
      .catch(() => {
        toastService.error('Error while updating authority');
        return Promise.reject();
      });
  },
  deleteUserAuthority(accessControlId: number): Promise<void> {
    return instance
      .delete(`/api/access-control/${accessControlId}`)
      .then(() => {
        toastService.success('Authority deleted successfully');
      })
      .catch(() => {
        toastService.error('Error while deleting authority');
        return Promise.reject();
      });
  },
  searchUsers(keyword: string): Promise<UserDTO[]> {
    return instance.get(`/api/users/search?keyword=${keyword}`).then(res => res.data);
  },
  getUsersByIds(ids: string): Promise<UserDTO[]> {
    return instance.get(`/api/users/by-ids?ids=${ids}`).then(res => res.data);
  },
  getUserDisclaimerAgreement(): Promise<boolean> {
    return instance.get('/api/users/disclaimer-agreement').then(res => res.data);
  },
  setUserDisclaimerAgreement(agreeDisclaimer: boolean): Promise<UserDTO> {
    return instance.patch('/api/users/disclaimer-agreement', agreeDisclaimer, { headers: { 'Content-Type': 'application/json' } });
  },
  getUserAvatar(): Promise<Blob> {
    return instance.get('/api/users/avatar').then(res => res.data);
  },
  uploadUserAvatar(body): Promise<void> {
    if (body.get('file').size > 1000000) {
      toastService.error('maximum avatar size is 1 mb');
      return Promise.reject();
    }
    return instance
      .post('/api/users/avatar', body)
      .then(() => {
        toastService.success('User avatar updated successfully');
      })
      .catch(() => {
        toastService.error('Error while updating user avatar');
        return Promise.reject();
      });
  },
};
