import OsSelection from "components/shared/os-selection/os-selection";
import { Slider } from "@mui/material";
import styles from 'components/file-upload/add-file-upload-styles.module.scss';

import { ChangeEvent } from 'react';

type Props = {
  filePath: string,
  handleFilePath: (event: ChangeEvent<HTMLInputElement>) => void,
  hasErrors: boolean,
  htmlErrorMessage: string,
  sasTokenLifetimeInHours: number,
  handleSasTokenLifetimeChange: (event: Event, newValue: number | number[]) => void,
};

const CommandLineUploadTab = ({
  filePath, handleFilePath, hasErrors, htmlErrorMessage, sasTokenLifetimeInHours, handleSasTokenLifetimeChange,
} : Props) => {
    
  const minSasTokenLifetimeInHours = 1;
  const maxSasTokenLifetimeInHours = 168;
  const sliderStep = 1;
  
  return (
    <div>
      <p className={styles.infoMessage}>
        Command line upload is best suited for <b>large</b> files that take hours to upload.<br/>
        To upload such files, please follow these steps :<br/><br/>
        1. Select your OS and a proxy if necessary<br/>
        2. <b>Enter the paths</b> of the files or directories you want to upload and click on upload<br/>
        To enter multiple paths, use ';' as a separator<br/>
        Example: C:\myDirectory;C:\directory\myFile<br/>
        3. An "upload-script" will be downloaded to your computer<br/>
        4. <b>Execute</b> that script and <b>wait</b> for completion of the upload<br/>
        5. The uploaded file is now accessible in <b>/import</b> of any session of the project<br/>
        <br/>
      </p>
      <OsSelection/>
      <br/>
      <input type='text' value={filePath} onChange={handleFilePath} placeholder='Enter file path'/>
      {hasErrors && (
        <p className={styles.errorMessage}>
          <span>Error :</span><br/>
          <span dangerouslySetInnerHTML={{ __html: htmlErrorMessage }}/>
        </p>
      )}
      <div>
        <p>Max upload time: {sasTokenLifetimeInHours} hours</p>
        <Slider value={sasTokenLifetimeInHours} min={minSasTokenLifetimeInHours} max={maxSasTokenLifetimeInHours} step={sliderStep} aria-labelledby='label' onChange={handleSasTokenLifetimeChange} />
      </div>
    </div>
  );
};

export default CommandLineUploadTab;