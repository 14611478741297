import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmDialog from 'components/shared/confirm-dialog/confirm-dialog';
import { connect, ConnectedProps } from 'react-redux';
import { formatGBtoTB } from 'services/size-format-service';
import { useAppDispatch } from 'store';
import { deleteVMOfSite } from 'store/actions/vm-instance-actions';
import { BillingInfoDTO } from 'types';
import EditSite from './edit-site';
import './site-cost.scss';

const getLabelRunningWorkstation = (isUserCosts, nbVMs) => {
  const prefix = isUserCosts ? 'My running sessions: ' : 'Site running sessions: ';
  return `( ${prefix} ${nbVMs == null || nbVMs == undefined ? 'unknown' : nbVMs} `;
};

const connector = connect(null);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  siteCost: BillingInfoDTO,
  isManager: boolean,
  isUserCostsOnly: boolean,
  displayDetails: boolean,
};

type Props = ReduxProps & OwnProps;

/**
 * Component used to display cost of a site.
 *
 * @param {isUserCostsOnly} Determines if all the displayed parameters are considering only the current user or all users of the site
 * @param {isManager} Determines if the current user is manager of the site and is allowed to kill all VMs or edit site.
 */
const SiteCost = ({ 
  siteCost,
  displayDetails,
  isManager,
  isUserCostsOnly,
}: Props) => {
  const dispatch = useAppDispatch();

  return (
    <div className='site-cost'>
      <div className='header'>
        <div>
          <span>{siteCost.site.label}</span>
          <span className='running-vm'>{getLabelRunningWorkstation(isUserCostsOnly, siteCost?.launchedVms)}</span>
          {isManager && !isUserCostsOnly && siteCost && (
            <ConfirmDialog title='Stop all VM' text={`Are you sure you want to stop all VM on "${siteCost?.site.label}" ?`} isMenuItem={false}
              onConfirm={() => dispatch(deleteVMOfSite(siteCost.site.id))}>
              <div className='justify-center'>
                <DeleteForeverIcon className='delete-icon' />
              </div>
            </ConfirmDialog>
          )}
        </div>
        {isManager && !isUserCostsOnly && siteCost && <EditSite siteInfo={siteCost.site} />}
      </div>
      <div className='content'>
        <div className='section'>
          <div className='section-label'>Total estimated cost: </div>
          <div className='section-items'>
            <div className='item'>
              <div className='machine-category-label'>Current month</div>

              {/* FIXME To reuse when user billing is implemented 
              <Link to={{ pathname: `/billing-users/${siteCost.site.id}` }} className='menu-icon-link'>
                <div className='value'>{siteCost.totalCost} <MoreHorizIcon className='valueIcon' fontSize='small' /></div>
              </Link> */}
              <div className='value'>{siteCost ? siteCost.totalCost + '$' : 'unknown'}</div>
            </div>

            <div className='item'>
              <div className='machine-category-label'>Previous month</div>
              <div className='value'>{siteCost ? siteCost.previousTotalCost + '$' : 'unknown'}</div>
            </div>
            <div className='item'>
              <div className='machine-category-label'>From January 1st</div>
              <div className='value'>{siteCost ? siteCost.costFrom1stJan + '$' : 'unknown'}</div>
            </div>
          </div>
        </div>

        {displayDetails && (
          <div className='section'>
            <div className='section-label'>Details:</div>
            <div className='section-items'>
              <div className='item'>
                <div className='machine-category-label'>Workstation</div>
                <div className='value'>{siteCost ? siteCost.vmCost + '$' : 'unknown'}</div>
              </div>

              <div className='item'>
                <div className='machine-category-label'>Storage</div>
                <div className='value'>{siteCost ? siteCost.storageCost + '$' : 'unknown'}</div>
              </div>

              <div className='item'>
                <div className='machine-category-label'>Infra</div>
                <div className='value'>{siteCost ? siteCost.infraCost + '$' : 'unknown'}</div>
              </div>

              <div className='item'>
                <div className='machine-category-label'>AKS</div>
                <div className='value'>{siteCost ? siteCost.aksCost + '$' : 'unknown'}</div>
              </div>
            </div>
          </div>
        )}

        <div className='section'>
          <div className='section-label'>Details per workstation type:</div>
          <div className='section-items'>
            <div className='item'>
              <div className='machine-category-label'>Standard</div>
              <div className='value'>{siteCost ? siteCost.vmStandardCost + '$' : 'unknown'}</div>
            </div>
            <div className='item'>
              <div className='machine-category-label'>Advanced</div>
              <div className='value'>{siteCost ? siteCost.vmAdvancedCost + '$' : 'unknown'}</div>
            </div>
            {!!siteCost?.vmCustomCost && (<div className='item'>
                <div className='machine-category-label'>Custom</div>
                <div className='value'>{siteCost ? siteCost.vmCustomCost + '$' : 'unknown'}</div>
              </div>)}
            <div className='item'>
              <div className='machine-category-label'>Other VM costs</div>
              <div className='value'>{siteCost ? siteCost.vmOtherCost + '$' : 'unknown'}</div>
            </div>
          </div>
        </div>
        <div className='section'>
          <div className='section-label'>Details per storage type:</div>
          <div className='list-section-items'>
            <div className='list-item'>
              <div className='machine-category-label'>Azure file Storage : </div>
              <div className='value'>{siteCost ? siteCost.azfCost + '$' : 'unknown'}</div>
              <div className='value'>{siteCost ? formatGBtoTB(siteCost.azfCapacity) : 'unknown'}</div>
            </div>
            <div className='list-item'>
              <div className='machine-category-label'>Netapp Standard :</div>
              <div className='value'>{siteCost ? siteCost.anfStandardCost + '$' : 'unknown'}</div>
              <div className='value'>{siteCost ? formatGBtoTB(siteCost.anfStandardCapacity) : 'unknown'}</div>
            </div>
            <div className='list-item'>
              <div className='machine-category-label'>Netapp Premium :</div>
              <div className='value'>{siteCost ? siteCost.anfPremiumCost + '$' : 'unknown'}</div>
              <div className='value'>{siteCost ? formatGBtoTB(siteCost.anfPremiumCapacity) : 'unknown'}</div>
            </div>
            <div className='list-item'>
              <div className='machine-category-label'>Netapp Ultra :</div>
              <div className='value'>{siteCost ? siteCost.anfUltraCost + '$' : 'unknown'}</div>
              <div className='value'>{siteCost ? formatGBtoTB(siteCost.anfUltraCapacity) : 'unknown'}</div>
            </div>
            <div className='list-item'>
              <div className='machine-category-label'>SCS :</div>
              <div className='value'>{siteCost ? siteCost.scsCost + '$' : 'unknown'}</div>
              <div className='value'>{siteCost ? formatGBtoTB(siteCost.scsCapacity) : 'unknown'}</div>
            </div>
            <div className='list-item'>
              <div className='machine-category-label'>Backup Storage :</div>
              <div className='value'>{siteCost ? siteCost.backupStorageCost + '$' : 'unknown'}</div>
            </div>
          </div>
          <div className='last-update'>
            Last update : {siteCost && siteCost.lastUpdateTimestamp ? siteCost.lastUpdateTimestamp : 'unknown'}
          </div>
        </div>
      </div>
    </div>
  );
};

SiteCost.defaultProps = {
  displayDetails: false,
};

export default connector(SiteCost);
