import ExpandLess from '@mui/icons-material/ArrowDropDown';
import ExpandMore from '@mui/icons-material/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import AddUsersIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import AddProjectIcon from '@mui/icons-material/LibraryAdd';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import HardDiskIcon from 'assets/images/icon_hard_disk.svg';
import classnames from 'classnames';
import Loader from 'components/loader/loader';
import CreateResource from 'components/resource/create-resource';
import ConfirmDialog from 'components/shared/confirm-dialog/confirm-dialog';
import CustomMenu from 'components/shared/menu/custom-menu';
import AddUsers from 'components/user/add-users';
import { MANAGER } from 'constants/user-authority';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteZone } from 'store/actions/resource-zone-actions';
import { RootState } from 'store/reducers';
import { ResourceAccessNode } from 'types';
import Project from './menu-project';

// TODO Split menu.module.scss
import { useAppDispatch } from 'store';
import styles from './sidebar.module.scss';

const mapState = (state: RootState, { zone }: OwnProps) => ({
  isFileshareReady: !!state.resourcesReducer.zoneId2IsFileshareReady[zone.resourceId],
  isFileshareFailed: !!state.resourcesReducer.zoneId2IsFileshareFailed[zone.resourceId],
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  site: ResourceAccessNode,
  zone: ResourceAccessNode,
};

type Props = ReduxProps & OwnProps;

const Zone = ({ site, zone, isFileshareReady, isFileshareFailed }: Props) => {
  const [manageMenuEl, setManageMenuEl] = useState(null);
  const [expandSiteList, setExpandSiteList] = useState(false);
  const dispatch = useAppDispatch();

  const isZoneManager = zone.authority === MANAGER;

  const showManageActions = event => {
    setManageMenuEl(event.currentTarget);
    event.stopPropagation();
  };

  const hideManageActions = event => {
    setManageMenuEl(null);
    if (event) {
      event.stopPropagation();
    }
  };

  const stopPropagation = event => {
    event.stopPropagation();
  };

  const manageItems = [
    {
      key: 'add-users',
      display: true,
      caption: (
        <AddUsers onClose={() => setManageMenuEl(null)} resourceData={{ site, zone }} withMenuStyle={true}>
          <div className='justify-center'>
            <AddUsersIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Add users</span>
          </div>
        </AddUsers>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'add-project',
      display: true,
      caption: isFileshareReady ? (
        <CreateResource
          resourceType='Project'
          selectedSiteId={site.resourceId} selectedZoneId={zone.resourceId}
          onClose={() => setManageMenuEl(null)}>
          <div className='justify-center'>
            <AddProjectIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Add project</span>
          </div>
        </CreateResource>
      ) : (
        <Tooltip title='Waiting for fileshare creation before creating projects'>
          <div className={'justify-center' + ' ' + styles.menu_item_content}>
            <AddProjectIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label_disabled}>Add project</span>
          </div>
        </Tooltip>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'delete',
      display: true,
      caption: (
        <ConfirmDialog
          title='Delete'
          text={site.isRunLevel
            ? `Warning! Other people may work on storage "${zone.name}", are you sure you want to delete it ?`
            : `Are you sure you want to delete storage "${zone.name}" from "${site.label}"`}
          isMenuItem
          onConfirm={() => dispatch(deleteZone(site.resourceId, zone.resourceId))}
          onClose={() => setManageMenuEl(null)}>
          <div className='justify-center'>
            <DeleteIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Delete</span>
          </div>
        </ConfirmDialog>
      ),
      onClick: hideManageActions,
    },
  ];

  return (
    <>
      <ListItem className={classnames(styles.sidebar__list_item_container, styles.sidebar__list_item_container_zone)} disableGutters button
                onClick={() => setExpandSiteList(!expandSiteList)}>
        <div className={styles.sidebar__list_item}>
          <div>
            {expandSiteList ? <ExpandLess className={styles.sidebar__icon} /> : <ExpandMore className={styles.sidebar__icon} />}

            <img src={HardDiskIcon} className={styles.sidebar__hard_disk_zone_icon} alt='Hard disk' />

            <ListItemText className={classnames(styles.sidebar__nav_item_text, styles.sidebar__overflow_ellipsis)}>
              <Typography sx={{ 'fontWeight': '500', 'lineHeight': '1.25' }}>
                {zone.name}
              </Typography>
            </ListItemText>

            {isZoneManager && !isFileshareReady && !isFileshareFailed && (
              <ListItemIcon>
                <Tooltip title='Checking storage readiness'>
                  <span>
                    <Loader className={styles.sidebar__loader} />
                  </span>
                </Tooltip>
              </ListItemIcon>
            )}

            {!isFileshareReady && isFileshareFailed && (
              <ListItemIcon>
                <Tooltip title='This storage is facing an issue, please contact support if this persists more than a few minutes.'>
                  <span>
                    <ReportProblemIcon className={styles.sidebar__action_icon} />
                  </span>
                </Tooltip>
              </ListItemIcon>
            )}
          </div>

          <div>
            <ListItemIcon className={styles.sidebar__action_icon} onClick={stopPropagation}>
              <Link to={{ pathname: `/zone-info/${zone.resourceId}` }}>
                <InfoIcon className={styles.sidebar__action_icon}/>
              </Link>
            </ListItemIcon>

            {isZoneManager && (
              <>
                <ListItemIcon aria-controls='simple-menu' aria-haspopup='true' onClick={showManageActions} 
                              className={styles.sidebar__action_icon} id='actionIconMore'>
                  <MoreIcon />
                </ListItemIcon>
                <CustomMenu open={Boolean(manageMenuEl)} menuItems={manageItems} anchorElement={manageMenuEl}
                      onClose={hideManageActions} />
              </>
            )}
            
          </div>
        </div>
      </ListItem>
      <Collapse in={expandSiteList}>
        <List className={styles.sidebar__project_list}>
          {zone.children
            .sort((p1, p2) => p1.name.localeCompare(p2.name))
            .map(project => (
              <Project key={project.resourceId} site={site} zone={zone} project={project}/>
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default connector(Zone);
