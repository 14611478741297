import { 
  RECEIVE_PROJECT_FILES, 
  CLEAR_PROJECT_FILES, 
  FileDownloadActionType
} from 'store/actions/file-download-actions';
import { FileDTO } from 'types';

export type DownloadState = {
  projectFiles: FileDTO[] | undefined,
  currentPage: number,
  pageSize: number,
  totalElements: number
};

const initialState: DownloadState = {
  projectFiles: undefined,
  currentPage: 0,
  pageSize: 100,
  totalElements: 0,
};

const fileDownloadReducer = (state = initialState, action: FileDownloadActionType): DownloadState => {
  switch (action.type) {
    case RECEIVE_PROJECT_FILES:
      return {
        ...state,
        projectFiles: action.projectFiles.content,
        currentPage: action.projectFiles.number,
        totalElements: action.projectFiles.totalElements,
      };

    case CLEAR_PROJECT_FILES:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default fileDownloadReducer;