import ScheduleIcon from '@mui/icons-material/AccessTime';
import DeleteSessionIcon from '@mui/icons-material/Cancel';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LaunchSessionIcon from '@mui/icons-material/PlayCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TVIcon from '@mui/icons-material/SettingsOverscan';
import Signal0Icon from '@mui/icons-material/SignalCellular0Bar';
import Signal1Icon from '@mui/icons-material/SignalCellular1Bar';
import Signal2Icon from '@mui/icons-material/SignalCellular2Bar';
import Signal3Icon from '@mui/icons-material/SignalCellular3Bar';
import Signal4Icon from '@mui/icons-material/SignalCellular4Bar';
import SignalUnknownIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalNullIcon from '@mui/icons-material/SignalCellularNull';
import SignalOffIcon from '@mui/icons-material/SignalCellularOff';
import { Button, Grid, IconButton, LinearProgress, Paper, Tooltip } from '@mui/material';
import { ReactComponent as AddSchedule } from 'assets/images/add-schedule.svg';
import { ReactComponent as DcvLogo } from 'assets/images/dcv-logo.svg';
import Loader from 'components/loader/loader';
import TextLimited from 'components/shared/text-limited/text-limited';
import { TOOLTIP_DELAY, TOOLTIP_LEAVE_DELAY } from 'constants/config';
import { BOOTING, COMPUTING, ERROR, FAILED, NO_MESSAGE_STATUS_EVENT, RUNNING, STOPPING, STOP_REQUEST, UNKNOWN, UNREACHABLE, UNRESPONSIVE } from 'constants/vm-status';
import { DateTime, Duration } from 'luxon';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatDateTimeFromMillis } from 'services/date-service';
import fileService from 'services/file-service';
import toastService from 'services/toast-service';
import vmInstanceService from 'services/vm-instance-service';
import { deleteVMInstance } from 'store/actions/vm-instance-actions';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { VmDTO } from 'types';
import { useInterval } from 'usehooks-ts';
import VmActionMenu from './vm-action-menu';
import VmComponentShare from './vm-component-share';
import EditLifeTimeVm from './vm-edit-lifetime';
import CustomResolutionVm from './vm-edit-resolution';

import { Link } from 'react-router-dom';
import styles from './vm-component.module.scss';

const difHours = (startDateMillis, endDateMillis) => {
  return (endDateMillis - startDateMillis) / 3600000;
};

export const getCurrentCost: (vm: VmDTO) => number = (vm) => {
  const now = new Date().getTime();
  const nbHours = difHours(vm.creationDateInMillis, now);
  if (nbHours < 0) return 0;
  return nbHours * vm.costPerHour;
};

export const getFinalCost: (vm: VmDTO) => number = (vm) => {
  const nbHours = difHours(vm.creationDateInMillis, vm.shutdownDateInMillis);
  if (nbHours < 0) return 0;
  return nbHours * vm.costPerHour;
};

export const getCPUUsage: (vm: VmDTO) => number = (vm: VmDTO) => {
  const num = Number(vm.cpuUsage * 100);
  return parseInt(num.toFixed(2));
};

const mapState = (state: RootState) => ({
  getSiteAvailableMachineTemplatesFromState: (siteId) => getSiteAvailableMachineTemplatesFromState(state, siteId),
});

const mapDispatch = (dispatch) => bindActionCreators(
  {
    deleteVMInstance,
  },
  dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  vmInstance: VmDTO,
  loggedUserId: string,
  createVM?: () => void,
};

type Props = ReduxProps & OwnProps;

// We use this export only for tests
const VMComponent = ({
  vmInstance, loggedUserId, createVM, deleteVMInstance, getSiteAvailableMachineTemplatesFromState, 
}: Props) => {
  const [countdown, setCountdown] = useState('');
  const [latency, setLatency] = useState(COMPUTING);
  const [waitingExpandingLifeTime, setWaitingExpandingLifeTime] = useState(false);
  const [stopping, setStopping] = useState(false); // True when stopping asked
  const instance = vmInstance;
  const isLoggedUserOwner = loggedUserId === instance.ownerId;
  const COUNT_DOWN_EXPIRED_VALUE = 'EXPIRED';

  const statusThatAllowVmDeletion = [RUNNING, BOOTING, FAILED, UNRESPONSIVE];
  const isVMReadyToBeStopped = () => {
    return (statusThatAllowVmDeletion.indexOf(instance.status) > -1) && !stopping;
  };

  const isVMReadyForUse = (vm: VmDTO) => {
    return vm.status === RUNNING && !stopping;
  };

  const downloadFile = () => fileService.downloadFile(vmInstanceService.getVNCAccessFileDownloadUrl(vmInstance.sessionId, latency !== UNREACHABLE)).catch((error) => {
    if (error.response.status === 403) {
      toastService.error('Error while while downloading DCV file, please contact your site manager if you need to extend your access.');
    }
  });
  const redirectToVmURL = () => fileService.redirectToVmURL(vmInstanceService.getVmURL(instance.sessionId, latency !== UNREACHABLE));
  const getCountDownTooltip = () => (countdown === COUNT_DOWN_EXPIRED_VALUE ? 'May be shut down at any time' : '');
  const getMachineTemplateLabel = (siteId, templateId) => {
    const template = getSiteAvailableMachineTemplatesFromState(siteId)?.find(t => t.id == templateId);
    return template?.name ?? '';
  };
  const getMachineTypeName = (instance) => instance.machineTemplateId || instance.machineTemplateId == 0 ? getMachineTemplateLabel(instance.siteId, instance.machineTemplateId).toUpperCase() : '';

  const expandLifetimeWithDate = (endDate: DateTime) => {
    setWaitingExpandingLifeTime(true);
    return vmInstanceService
      .expandLifetime(instance.sessionId, endDate)
      .then(() => {
        setTimeout(() => setWaitingExpandingLifeTime(false), 5000);
      })
      .catch(error => {
        setWaitingExpandingLifeTime(false);
        throw error;
      });
  };

  const getDisplayName = (siteName, zoneName, projectName) => {
    let displayName = `${siteName}`;
    if (zoneName || projectName) {
      displayName = `${siteName}:${zoneName}:${projectName}`;
    }
    return displayName;
  };

  useInterval(() => {
    const durationInMillis = (instance.shutdownDateInMillis || 0) - Date.now();

    if (durationInMillis <= 0) {
      setCountdown(COUNT_DOWN_EXPIRED_VALUE);
      return;
    }

    const duration = Duration.fromMillis(durationInMillis);
    const formattedDuration = duration.toFormat('d h m s');
    const durationSplit = formattedDuration.split(' ');

    const formattedCountdown = `${durationSplit[0]}d:${durationSplit[1]}h:${durationSplit[2]}m:${durationSplit[3]}s`;
    setCountdown(formattedCountdown);
  }, 1000);

  useInterval(() => {
    if (!isVMReadyForUse(instance)) setLatency(COMPUTING);
    else {
      vmInstanceService.pingVM(instance.vmBaseUrl).then(setLatency);
    }
  }, (instance.status !== STOPPING ? 15000 : null));

  useEffect(() => {
    // Early latency check
    if (isVMReadyForUse(instance)) {
      vmInstanceService.pingVM(instance.vmBaseUrl).then(setLatency);
    }
  }, []); // Empty dep array to prevent autocleanup of effects above that would break the setInterval logic

  const changeResolution = (h, w) => {
    instance.resolution.width = w;
    instance.resolution.height = h;
  };

  const getTooltipCost = vm => {
    return `${getCurrentCost(vm).toFixed(2)}$ already spent, total estimated at ${getFinalCost(vm).toFixed(2)}$ (${vm.costPerHour}$/h)`;
  };

  const getCostLabel = vm => {
    return `${getCurrentCost(vm).toFixed(2)}$ of ${getFinalCost(vm).toFixed(2)}$ (${vm.costPerHour}$/h)`;
  };

  const getStatus = vm => {
    const status = ['RUNNING', 'BOOTING'];
    if (stopping && status.includes(vm.status)) {
      return STOP_REQUEST;
    }
    return vm.status;
  };

  const truncateVmEvent = (eventLabel) => {
    const words = eventLabel.split(' ');
    let summ = 0;
    for (const [index, value] of words.entries()) {
      summ += value.length + 1;
      if (summ > 45) {
        if (index == 0){
          return value.substring(0, 45) + '...';
        } else {
          return words.slice(0, index).join(' ') + '...';
        }
      }
    }
    return words.join(' ');
  };

  const renderVmInstanceScreenshot = () => {
    if (instance.status === ERROR) {
      return (
        <Grid item className={styles.projectScreenShot}>
          <Grid container direction='column' justifyContent='center' alignItems='center' spacing={0}>
            <Grid item>
              <IconButton onClick={() => createVM && createVM()}>
                <LaunchSessionIcon className={[styles.icon, styles.sessionLaunchIcon].join(' ')} />
              </IconButton>
            </Grid>
            <Grid item className={styles.projectStatus}>
              Error
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item className={[styles.projectScreenShot, styles.projectPendingScreenShot].join(' ')}>
        {!isVMReadyForUse(instance) ? (
          <>
            {
              instance.lastEvent && (
                <Tooltip title='Last event send by the virtual machine'>
                  <div className={styles.labelWithTooltip}>
                    {instance.lastEvent.label === NO_MESSAGE_STATUS_EVENT ? '' : instance.lastEvent.label}
                  </div>
                </Tooltip>
              )
            }
            <Loader className={styles.projectPendingLoader} />
            <div className={styles.projectStatusLabel}>
              <span>Session {getMachineTypeName(instance)} : </span>
              <strong className={styles.projectStatus}>{getStatus(instance)}</strong>
            </div>
          </>
        ) : (
          <div className={styles.runningContainer}>
              <Tooltip title={'CPU : ' + getCPUUsage(instance) + ' %'}>
                <LinearProgress className={styles.cpuProgressLine} variant='determinate' value={getCPUUsage(instance)} />
              </Tooltip>
              <Link to={{ pathname: '/vm/latency/' + encodeURIComponent(`${instance.vmBaseUrl}`) }}>
                <Tooltip
                  title={
                    (+latency === COMPUTING) ? 'Computing latency, please wait a few seconds' :
                      (+latency === UNKNOWN) ? 'Could not determine your latency, could be a limitation of your web-browser, if this persists please use a recent chrome browser' :
                        (+latency === UNREACHABLE) ? 'Your session is unreachable at this time, please wait a few minutes, if this persists contact the support' :
                          'Your latency to the session is ' + latency + 'ms (the lower the better)'
                  } aria-label='Latency'>
                  {
                    (+latency === -1) ? <SignalNullIcon className={styles.connectivityBlinkIcon} /> :
                      (+latency === UNKNOWN) ? <SignalUnknownIcon className={styles.connectivityBlinkIcon} /> :
                        (+latency === UNREACHABLE) ? <SignalOffIcon className={styles.connectivityRedIcon} /> :
                          (+latency < 25) ? <Signal4Icon className={styles.connectivityIcon} /> :
                            (+latency < 50) ? <Signal3Icon className={styles.connectivityIcon} /> :
                              (+latency < 75) ?
                                <Signal2Icon className={styles.connectivityIcon} /> :
                                (+latency < 105) ?
                                  <Signal1Icon className={styles.connectivityIcon} /> :
                                  <Signal0Icon className={styles.connectivityIcon} />
                  }
                </Tooltip>
              </Link>
              {
                instance.temporaryDatalocal && (
                  <Tooltip title='Warning! This session uses a temporary datalocal. /data_local content will not be persisted after VM shutdown.'>
                    <div className={styles.labelWithTooltip}>
                      <ReportProblemIcon fontSize='small' style={{ color: 'lightgrey' }} />
                      <span>Temporary Datalocal</span>
                    </div>
                  </Tooltip>
                )
              }

              {
                instance.lastEvent && (
                  <Tooltip title='Last event send by the virtual machine'>
                    <div className={styles.vmEventWithTooltip}>
                      {instance.lastEvent.label === NO_MESSAGE_STATUS_EVENT ? '' : truncateVmEvent(instance.lastEvent.label)}
                    </div>
                  </Tooltip>
                )
              }

              {instance.resolution && instance.resolution.height > 0 && instance.resolution.width > 0 && (
                <div className={styles.resolutionContainer}>
                  <CustomResolutionVm vmInstance={instance} onUpdate={changeResolution}>
                    <Tooltip enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY} leaveDelay={TOOLTIP_LEAVE_DELAY}
                      title={'Custom resolution: ' + instance.resolution.width + 'x' + instance.resolution.height} aria-label='customRes'>
                      <TVIcon className={styles.resolutionIcon} />
                    </Tooltip>
                  </CustomResolutionVm>
                </div>
              )}

            <div className={styles.vmActionContainer}>
              <VmActionMenu vmInstance={instance} loggedUserId={loggedUserId} />
            </div>

            <div className={styles.statusRunningContainer}>
              <span>Session {getMachineTypeName(instance)} : </span>
              <strong className={styles.projectStatus}>{instance.status}</strong>
            </div>

            <Tooltip title='Open with DCV client' enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY} leaveDelay={TOOLTIP_LEAVE_DELAY} aria-label='dcv'>
              <Button variant='contained' color='primary' className={[/*styles.button,*/ styles.downloadDcvFileButton].join(' ')} onClick={downloadFile}>
                <PlayArrowIcon />
                <DcvLogo title='' />
              </Button>
            </Tooltip>
            <Tooltip title='Open in browser' enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY} leaveDelay={TOOLTIP_LEAVE_DELAY} aria-label='browser'>
              <Button variant='contained' color='primary' className={styles.openVmUrlButton} onClick={redirectToVmURL}> <PlayArrowIcon />
                <LanguageIcon />
                <span>WEB</span>
              </Button>
            </Tooltip>
            {countdown && (
              <>
                <div className={styles.addScheduleContainer}>
                  <ScheduleIcon className={styles.scheduleIcon} />

                  <Tooltip title={getCountDownTooltip()} aria-label='countdown'>
                    <span className={styles.countdown}>{countdown}</span>
                  </Tooltip>

                  {isLoggedUserOwner && (
                    <>
                      <EditLifeTimeVm vmInstance={instance} onUpdate={expandLifetimeWithDate}>
                        <Tooltip title='Extend lifetime' enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY} leaveDelay={TOOLTIP_LEAVE_DELAY} aria-label='lifetime'>
                          <Button variant='outlined' className={styles.addScheduleButton} aria-label='Extend lifetime' disabled={waitingExpandingLifeTime}>
                            {waitingExpandingLifeTime ? <Loader className={styles.loaderWaitingExpandingLifeTime} /> :
                              <AddSchedule />
                            }
                          </Button>
                        </Tooltip>
                      </EditLifeTimeVm>
                    </>
                  )}
                </div>
                <div className={styles.costContainer}>
                  <Tooltip title={getTooltipCost(instance)} enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY} leaveDelay={TOOLTIP_LEAVE_DELAY} aria-label='cost'>
                    <span>{getCostLabel(instance)}</span>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        )
        }
      </Grid>
    );
  };

  return (
    <div className={styles.unselectable}>

      <Grid container direction='row' justifyContent='flex-start' spacing={0} className={styles.vmComponentGrid}>
        <Grid item>
          <Paper className={styles.paper}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems='center' direction='column' justifyContent='center'>
                {renderVmInstanceScreenshot()}
                <Grid item xs={12} className={styles.fullWidthContainer}>
                  <Grid container className={styles.projectDetailsContainer}>
                    <Grid item className={styles.projectDetails}>
                      <Grid container className={styles.projectDetails} direction='column' justifyContent='space-between' /*alignItems='space-between'*/>
                        <Grid item>
                          <div className={styles.instanceName}>
                            <span>
                              <TextLimited text={instance.name} limit={28} />
                            </span>
                          </div>
                        </Grid>
                        {instance.homeDirFull && (
                          <Grid item>
                            <div className={styles.homeDirFull}>
                              <ReportProblemIcon fontSize='small' style={{ color: 'orange' }} />
                              <span>Warning: Your home directory is full. Please move some data to /data_local.</span>
                            </div>
                          </Grid>
                        )}
                        {instance.siteName && (
                          <Grid item>
                            <div className={styles.siteZoneProjectName}>
                              <TextLimited text={getDisplayName(instance.siteLabel, instance.zoneName, instance.projectName)} limit={35} />
                            </div>
                          </Grid>
                        )}
                        {instance.ownerLastName ? (
                          <Grid item>
                            <Grid container justifyContent='center' alignItems='center'>
                              <Grid item className={styles.ownerName}>
                                <Tooltip title={instance.ownerEmail} enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY}
                                  leaveDelay={TOOLTIP_LEAVE_DELAY}>
                                  <div>
                                    {instance.ownerFirstName + ' ' + instance.ownerLastName}
                                  </div>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}
                        {instance.creationDate ? (
                          <Grid item>
                            <Grid container justifyContent='center' alignItems='center'>
                              <Grid item className={styles.creationDate}>
                                <Tooltip
                                  title={'Session started: ' + instance.creationDate}
                                  enterDelay={TOOLTIP_DELAY}
                                  enterNextDelay={TOOLTIP_DELAY}
                                  leaveDelay={TOOLTIP_LEAVE_DELAY}
                                  aria-label='createdOn'>
                                  <div>
                                    {formatDateTimeFromMillis(instance.creationDateInMillis)}
                                  </div>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                    {instance.name && (
                      <Grid container className={styles.sharingButton}>
                        <Grid item className={styles.sharingButton}>
                          {isLoggedUserOwner && isVMReadyForUse(instance) && (
                            <VmComponentShare vmInstance={instance} contributorIds={instance.contributorIds} />
                          )}
                          {!isLoggedUserOwner && isVMReadyForUse(instance) && (
                            <div className={styles.shareInstanceSharedWithMe}>
                              <Tooltip title={'Shared by ' + instance.ownerId} enterDelay={TOOLTIP_DELAY} enterNextDelay={TOOLTIP_DELAY} leaveDelay={TOOLTIP_LEAVE_DELAY} aria-label='lifetime'>
                                <span>
                                  <IconButton disabled>
                                    <PeopleOutlineOutlinedIcon className={styles.shareInstanceSharedWithMeDisabled} />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          {isLoggedUserOwner && isVMReadyToBeStopped() && (
            <IconButton className={[styles.deleteIconButton, 'delete-vm-button'].join(' ')} onClick={() => {
              setStopping(true);
              deleteVMInstance(vmInstance);
            }}>
              <DeleteSessionIcon className={styles.deleteSessionIcon} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
};


export default connector(VMComponent);