import { useRef, useState } from 'react';

import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { ClickAwayListener, Grow, MenuList, Paper, Popper, Button } from '@mui/material';

import styles from './contact-support.module.scss';
import mailTemplateService, { MailTemplate } from 'services/mail-template-service';

const ContactSupport = (vmInstanceList) => {

  const testId = 'contact-support-button';
  const testIdContactSismageSupport = 'contact-support-button-1';
  const testIdContactSaasSupport = 'contact-support-button-2';

  const buttonRef = useRef(null);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const handleClickOnMenuButton = () => {
    setActionMenuOpen(!actionMenuOpen);
  };

  const closeMenu = () => {
    setActionMenuOpen(false);
  };

  const getVmsName = () => {
    if (vmInstanceList.vmInstanceList.length <= 0) {
      return `
        no vm launched at this time
      `;
    }
    const vmsNameList: string[] = [];
    vmInstanceList.vmInstanceList.forEach(vmInstanceList => {
      vmsNameList.push(vmInstanceList.name);
    });
    
    return vmsNameList.join(', %0D%0A');
  };

  //%0D%0A endline
  const mailBody = {
    problem: 'I am having problems with: example: launch small vm.....',
    version: 'The sismage-saas version is:  ' + process.env.REACT_APP_VERSION,
    url: 'portal url: ' + window.location.href,
    description: 'description: give some description about your issue .....',
    vmlist: 'My active VMs: %0D%0A' + getVmsName(),
  };

  const sismageTemplateMail: MailTemplate = {
    mailto: ['helpdesk.sismage-cig@totalenergies.com'],
    ccs: [],
    subject: '',
    body: '',
  };

  const saasIssuesTemplateMail: MailTemplate = {
    mailto: ['ep.sismage-saas@totalenergies.com'],
    ccs: [],
    subject: '',
    body: mailTemplateService.createMailBody(mailBody),
  };
    
  return (
    <>
        <Button data-testid={testId} className={styles.headerMenuButton} ref={buttonRef} onClick={handleClickOnMenuButton}>
            <div>
                <span className={styles.text}>Contact support</span>
            </div>
            <ArrowDownIcon sx={{ 'color': 'grey' }}/>
        </Button>

        <Popper open={actionMenuOpen} anchorEl={buttonRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                <Paper>
                <ClickAwayListener onClickAway={closeMenu}>
                    <MenuList>
                        <Button data-testid={testIdContactSismageSupport} className={styles.menuItemButton} onClick={closeMenu} href={mailTemplateService.createHrefTemplateMail(sismageTemplateMail)}>
                            <EmailOutlinedIcon sx={{ 'color': 'grey' }}/>
                            <span className={styles.menuItemButtonText}>For SISMAGE issue</span>
                        </Button>
                        <Button data-testid={testIdContactSaasSupport} className={styles.menuItemButton} onClick={closeMenu} href={mailTemplateService.createHrefTemplateMail(saasIssuesTemplateMail)}>
                            <EmailOutlinedIcon sx={{ 'color': 'grey' }}/>
                            <span className={styles.menuItemButtonText}>For SAAS issue</span>
                        </Button>
                    </MenuList>
                </ClickAwayListener>
                </Paper>
            </Grow>
        )}
        </Popper>
    </>
  );
};
    
export default ContactSupport;